<template>
    <div>
        <el-button @click="test">测试</el-button>
    </div>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods:{
        test(){
            this.Api.Login.test().then(res => {
                console.log(res)
            })
        }
    }
}
</script>

<style scoped>

</style>